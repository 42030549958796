import React from 'react'
import {NavLink} from 'react-router-dom'
import './styles.css'

function Nav() {
  return (
    <>
      <NavLink id="navItem" to="/" className="body" activeClassName="active" end>
        J
      </NavLink>
      <NavLink id="navItem" to="/work" className="body" activeClassName="active">
        work
      </NavLink>
      <NavLink id="navItem" to="/fun" className="body" activeClassName="active">
        fun
      </NavLink>
    </>
  )
}

export default Nav
